<template>
  <div class="search">
    <div class="search-input">
      <span>角色名称：</span>
      <el-input
        v-model="name"
        @change="handleInput"
        placeholder="请输入角色名"
      ></el-input>
    </div>
    <el-button class="add_btn" type="primary" @click="handleOpenDialog(1)"
      >新增</el-button
    >
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    handleInput: Function,
    handleOpenDialog: Function,
  },
  data() {
    return {
      name: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
/deep/.el-input {
  width: 78% !important;
}
.search {
  position: relative;
  margin-bottom: 20px;
  &-input {
    width: 20%;
  }
  .add_btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
